import { h, resolveComponent } from 'vue'

const ListadoPacientes = () => import('@/app/views/pacientes/views/ListadoPacientes');
const DetallePaciente = () => import('@/app/views/pacientes/views/DetallePaciente');
const NuevoPaciente = () => import('@/app/views/pacientes/views/NuevoPaciente');


const pacientesRoutes = [
  {
    path: 'pacientes',
    name: 'Pacientes',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      breadcrumb: {
        title: 'GLOBAL.BREADCRUMBS.pacientes' // Clave de traducción para el breadcrumb principal
      }
    },
    children: [
      {
        path: '',
        name: 'pacientes:Listado',
        component: ListadoPacientes,
        meta: {
          auth: true,
          entidad: 'pacientes',
          breadcrumb: {
            title: "GLOBAL.BREADCRUMBS.listado"
          }
        }
      },
      {
        path: 'nuevo',
        name: 'pacientes:Nuevo',
        component: NuevoPaciente,
        meta: {
          auth: true,
          entidad: 'pacientes',
          breadcrumb: {
            title: "GLOBAL.BREADCRUMBS.nuevo"
          }
        },
      },
      {
        path: ':id',
        name: 'pacientes:Detalle',
        component: DetallePaciente,
        meta: {
          auth: true,
          entidad: 'pacientes',
          breadcrumb: {
            title: "GLOBAL.BREADCRUMBS.detalle"
          }
        },
      }
    ]
  }
];

export default pacientesRoutes;

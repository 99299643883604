import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'bootstrap';

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/app/router'
import store from './store'
import moment from 'moment';
import { Form, Field, ErrorMessage } from 'vee-validate'
import VueNotifications from '@kyvg/vue3-notification'
import { plugin } from 'vue3-cute-component';
import 'vue3-cute-component/dist/style.css';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'


import FontAwesomeIcon from "@/utilities/fontawesome-icons";
import '@/utilities/validator'

//Modals
import DeleteModal from "@/app/shared/components/modals/DeleteModal";
import ConfirmarModal from "@/app/shared/components/modals/ConfirmarModal";
import SubirFicherosModal from "@/app/shared/components/modals/SubirFicherosModal";
import EditarFicherosModal from "@/app/shared/components/modals/EditarFicherosModal";
import SeleccionarDireccionModal from "@/app/shared/components/modals/SeleccionarDireccionModal";
// import EditarFicherosPerfil from "@/app/shared/components/modals/EditarFicherosPerfil";

// import VerImagenModal from "@/app/shared/components/modals/VerImagenModal";
import VerImagenCarouselModal from "@/app/shared/components/modals/VerImagenCarouselModal";

//Components
import Toast from "@/app/shared/components/Toast";
import SpinnerTtt from '@/app/shared/components/SpinnerTtt';
import CustomTimeLine from '@/app/shared/components/CustomTimeline';



import DragDropInput from '@/app/shared/components/DragDropInput';
import BreadcrumpsCustom from '@/app/shared/components/BreadcrumpsCustom';

import CardListadoLogs from '@/app/shared/components/CardListadoLogs';
import LocaleChanger from '@/app/shared/components/LocaleChanger';



//Lists

import DireccionesList from '@/app/shared/components/lists/DireccionesList'
import i18n from './i18n'; // Importas la configuración


const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n) //vue-i18n


function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb)
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}

app.use(VueNotifications, options);
app.use(CoreuiVue)
app.use(plugin);


app.provide('icons', icons)
app.component('Form', Form)
app.component('Field', Field)

app.component('SimpleTypeahead', SimpleTypeahead)
app.component("FontAwesomeIcon", FontAwesomeIcon)

app.component('CIcon', CIcon)
app.component('toast', Toast);
app.component('DeleteModal', DeleteModal);
app.component('SeleccionarDireccionModal', SeleccionarDireccionModal);

app.component('ConfirmarModal', ConfirmarModal);
app.component('VerImagenCarouselModal', VerImagenCarouselModal);
app.component('DireccionesList', DireccionesList);
app.component('SubirFicherosModal', SubirFicherosModal);
app.component('EditarFicherosModal', EditarFicherosModal);
app.component('DragDropInput', DragDropInput);
app.component('SpinnerTtt', SpinnerTtt);
app.component('CustomTimeLine', CustomTimeLine);
app.component('QuillEditor', QuillEditor);
app.component('BreadcrumpsCustom', BreadcrumpsCustom);
app.component('CardListadoLogs', CardListadoLogs);
app.component('LocaleChanger', LocaleChanger);


moment.locale('es');
window.moment = moment;

app.mount('#app')

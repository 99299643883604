const Dashboard = () => import("@/app/views/home/views/Dashboard");

const homeRoutes = [
  {
    path: "dashboard",
    name: "",
    component: Dashboard,
    meta: {
      auth: true,
      breadcrumb: {
        title: "GLOBAL.BREADCRUMBS.dashboard"
      }
    }
  },
];

export default homeRoutes;

<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{ $t(!item.id ? 'GLOBAL.CENTRO.nueva_direccion' : 'GLOBAL.CENTRO.editar_direccion') }} :: {{ relacion }}</CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>
      <CModalBody>
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow>
            <CCol sm="6" class="mb-3">
                <CFormLabel>{{$t('GLOBAL.CAMPOS.principal')}}</CFormLabel>
                <CFormSwitch
                  id="principal" name="principal"
                  v-model="item.principal"
                  size="xl"
                  @update:checked="checked => item.principal = checked"
                />
              </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel>{{$t('GLOBAL.CAMPOS.razon_social')}}</CFormLabel>
              <CFormInput
                type="text"
                id="alias" name="alias"
                :placeholder="$t('GLOBAL.FORMS.placeholder.add_razon_social')"
                v-model="item.nombre"
              />
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">{{$t('GLOBAL.CAMPOS.nif')}}</CFormLabel>
              <Field
                as="CFormInput"
                id="nif" name="nif"
                type="text"
                :placeholder="$t('GLOBAL.FORMS.placeholder.add_nif')"
                :rules="'required'"
                v-model="item.cif"
                :class="{ 'is-invalid': formError(errors, 'nif')}"
                v-bind:invalid-feedback="errors.nif"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'nif')">{{ errors.nif }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">{{$t('GLOBAL.CAMPOS.direccion')}}</CFormLabel>
              <Field
                as="CFormInput"
                id="direccion" name="direccion"
                type="text"
                :placeholder="$t('GLOBAL.FORMS.placeholder.add_direccion')"
                :rules="'required'"
                v-model="item.direccion"
                :class="{ 'is-invalid': formError(errors, 'direccion')}"
                v-bind:invalid-feedback="errors.direccion"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'direccion')">{{ errors.direccion }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">{{$t('GLOBAL.CAMPOS.pais')}}</CFormLabel>
              <Field
                as="CFormInput"
                id="pais" name="pais"
                type="text"
                :placeholder="$t('GLOBAL.FORMS.placeholder.add_pais')"
                :rules="'required'"
                v-model="item.pais"
                :class="{ 'is-invalid': formError(errors, 'pais')}"
                v-bind:invalid-feedback="errors.pais"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'pais')">{{ errors.pais }}</small>
            </CCol>
          </CRow>
          <CRow>
          
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">{{$t('GLOBAL.CAMPOS.provincia')}}</CFormLabel>
              <Field
                as="CFormInput"
                id="provincia" name="provincia"
                type="text"
                 :placeholder="$t('GLOBAL.FORMS.placeholder.add_provincia')"
                :rules="'required'"
                v-model="item.provincia"
                :class="{ 'is-invalid': formError(errors, 'provincia')}"
                v-bind:invalid-feedback="errors.provincia"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'provincia')">{{ errors.provincia }}</small>
            </CCol>
         
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">{{$t('GLOBAL.CAMPOS.localidad')}}</CFormLabel>
              <Field
                as="CFormInput"
                id="localidad" name="localidad"
                type="text"
                :placeholder="$t('GLOBAL.FORMS.placeholder.add_localidad')"
                :rules="'required'"
                v-model="item.localidad"
                :class="{ 'is-invalid': formError(errors, 'localidad')}"
                v-bind:invalid-feedback="errors.localidad"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'localidad')">{{ errors.localidad }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">{{$t('GLOBAL.CAMPOS.cp')}}</CFormLabel>
              <Field
                as="CFormInput"
                id="codigo_postal" name="código postal"
                type="text"
                :placeholder="$t('GLOBAL.FORMS.placeholder.add_cp')"
                :rules="'required'"
                v-model="item.codigo_postal"
                :class="{ 'is-invalid': formError(errors, 'código postal')}"
                v-bind:invalid-feedback="errors['código postal']"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'código postal')">{{ errors['código postal'] }}</small>
            </CCol>
          </CRow>

        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">{{$t('GLOBAL.BTN.cancelar')}}</CButton>
        <CButton v-if="item.id" class="text-light" @click="guardarDireccion" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Guardar
        </CButton>
        <CButton v-else class="text-light" @click="crearDireccion" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          {{$t('GLOBAL.BTN.crear')}}
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { fetchTypologyWithSons } from '@/app/shared/global/services/tipologia-service';

import { DIRECCIONES_FIELDS } from '@/config';
import { formError, setStoreData } from '@/app/shared/utils/tools';

export default {
  name: 'DireccionModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    direccion: {type: Object},
    relacion: String
  },
  emits: ['crearDireccion', 'guardarDireccion', 'cancelled'],
  computed: {
    showModal() {
      return this.show;
    },
    item: {
      get: function () {
        return this.direccion
      },
      set: function (newValue) {
        this.$emit("update:direccion", newValue);
      }
    },
  },
  data() {
    return {
      listadoTipos: [],
      loading: false,
      tipologiaSlug: 'tipos-de-direccion'
    }
  },
  methods: {
    crearDireccion() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('crearDireccion');
      })
    },

    guardarDireccion() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('guardarDireccion')
      })
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    cerrarModal() {
      this.loading = false;
      this.item = {...DIRECCIONES_FIELDS};
      this.$emit('cancelled',false);
    }
  }
}
</script>

<style>

</style>




<template>
  <div class="animated fadeIn" :title="$t('GLOBAL.SALDO.MODAL.selecciona_una_direccion')">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{ title }}</CModalTitle>
      </CModalHeader>

      <CModalBody class="text-center d-block">
        <CListGroup>
          <CListGroupItem
            v-for="direccion in direcciones"
            :key="direccion.id"
            action
            @click="seleccionar(direccion)"
            :class="{'active': direccionSeleccionada && direccionSeleccionada.id === direccion.id}"
          >
            {{ direccion.direccion }}
          </CListGroupItem>
        </CListGroup>
        <slot></slot>
      </CModalBody>

       <CModalFooter>
        <!--<CButton @click="$emit('cancelled',false)" color="outline-warning">{{$t('GLOBAL.BTN.cancelar')}}</CButton>-->
        <CButton @click="emitirSeleccion" color="primary">{{$t('GLOBAL.BTN.confirmar')}}</CButton>
        <!-- <CButton class="text-light" @click="$emit('deleteItems',false)" color="danger"> -->
          <!-- <CIcon name="cil-check-circle" />  -->
          <!-- Eliminar
        </CButton> -->
       </CModalFooter>

    </CModal>
    <toast ref="toast"></toast>

  </div>
</template>

<script>

export default {
  name: 'DeleteModal',
  props: {
    title: { type: String, required: true },
    show: { type: Boolean, default: false, required: true },
    direcciones: { type: Array, required: true },
  },
  data() {
    return {
      direccionSeleccionada: null, // Variable para almacenar la dirección seleccionada
    };
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  methods: {
    seleccionar(direccion) {
      this.direccionSeleccionada = direccion; // Guardar la dirección seleccionada
    },
    emitirSeleccion() {

      //console.log('entro a emitir seleccion')
      if (this.direccionSeleccionada) {

        console.log('direccion selec', this.direccionSeleccionada)
        this.$emit('direccionSeleccionada', this.direccionSeleccionada); // Emitir la dirección seleccionada al componente padre
        this.$emit('cancelled', false); // Cerrar el modal
      } else {
        this.$refs.toast.showToast(this.$t("GLOBAL.SALDO.MODAL.debes_seleccionar_direccion"), null, "error", "3000"); // Mostrar mensaje de error si no hay dirección seleccionada
      }
    },
  }
}
</script>
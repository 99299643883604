export default [
  {
    component: 'CNavItem',
    name: 'GLOBAL.RUTAS.dashboard',
    to: '/dashboard',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'GLOBAL.RUTAS.expedientes.main',
    icon: 'cil-clipboard',
    to: '/expedientes',
    entidad: 'expedientes'
  },
  {
    component: 'CNavItem',
    name: 'GLOBAL.RUTAS.pacientes.main',
    icon: 'cil-people',
    to: '/pacientes',
    entidad: 'pacientes'
  },
  {
    component: 'CNavItem',
    name: 'GLOBAL.RUTAS.saldo.main',
    icon: 'cil-money',
    to: '/saldo',
    entidad: 'saldo'
  },
  {
    component: 'CNavItem',
    name: 'GLOBAL.RUTAS.documentacion.main',
    icon: 'cil-book',
    to: '/manual',
    entidad: 'manual'
  },
  {
    component: 'CNavItem',
    name: 'GLOBAL.RUTAS.centro.main',
    icon: 'cil-user',
    to: '/centros/yo',
    entidad: 'centro'
  },
]

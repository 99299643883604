import { CENTROS_API, BASE_URL } from "@/config";

export const apiGlobalConstants = {
  centro: CENTROS_API,
  administrador: `${CENTROS_API}/administradores`,
  dashboard: `${BASE_URL}/centros`,
  tipologia: `${BASE_URL}/publico/tipologias`,
  fichero: `${CENTROS_API}/ficheros`,
  direccion: `${CENTROS_API}/direcciones`,
  log: `${CENTROS_API}/logs`,
  informes: `${CENTROS_API}/informes`,
  expedientes: `${CENTROS_API}/expedientes`,
  pacientes: `${CENTROS_API}/pacientes`,
  medicos: `${CENTROS_API}/medicos`,
  centros: `${CENTROS_API}/centro`,
  saldos: `${CENTROS_API}/movimientos`,
  lesiones: `${CENTROS_API}/lesiones`,
  manual: `${CENTROS_API}/manual`,
  publica: `${BASE_URL}/expedientes`,
  factura: `${CENTROS_API}/facturas`,
}

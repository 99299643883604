<template>
  <CFooter>
    <div>
      <a href="https://aederma.es/" target="_blank">{{ COMPANY_DATA.company_name }}</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div> 
    <div class="m-auto">
        <a :href="$t('GLOBAL.pdfs.terminos_condiciones')" class="px-2" target="_blank">{{$t('GLOBAL.RUTAS.footer.terminos_y_condiciones')}}</a> |
        <a :href="$t('GLOBAL.pdfs.aviso_legal')" class="px-2" target="_blank">{{$t('GLOBAL.RUTAS.footer.avisos_legales')}}</a> 
      | <a :href="$t('GLOBAL.pdfs.cookies')" class="px-2" target="_blank">{{$t('GLOBAL.RUTAS.footer.politica_de_cookies')}}</a>
    </div>
    <div class="">
    <!-- <div class="ms-auto"> -->
      <!-- <span class="me-1">Desarollado por</span>
      <a href="https://www.trestristestigres.com" target="_blank"><b>tres</b>tristes<b>tigres</b></a> -->
    </div>
  </CFooter>
</template>

<script>
import { COMPANY_DATA } from '@/config';
export default {
  name: 'AppFooter',
  setup() {
    return {
      COMPANY_DATA
    }
  },
}
</script>

import { h, resolveComponent } from 'vue'

const ListadoSaldo = () => import('@/app/views/saldo/views/ListadoSaldo');


const saldoRoutes = [
  {
    path: 'saldo',
    name: 'Saldo',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      breadcrumb: {
        title: 'GLOBAL.BREADCRUMBS.saldo'
      }
    },
    children: [
      {
        path: '',
        name: 'saldo:Listado',
        component: ListadoSaldo,
        meta: {
          auth: true,
          entidad: 'saldo',
          breadcrumb: {
            title: "GLOBAL.BREADCRUMBS.listado"
          }
        },
      }
    ]
  }
];

export default saldoRoutes;

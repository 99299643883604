import { h, resolveComponent } from 'vue'

const ListadoExpedientes = () => import('@/app/views/expedientes/views/ListadoExpedientes');
const DetalleExpediente = () => import('@/app/views/expedientes/views/DetalleExpediente');
const NuevoExpediente = () => import('@/app/views/expedientes/views/NuevoExpediente');


const expedientesRoutes = [
  {
    path: 'expedientes',
    name: 'Expedientes',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      breadcrumb: {
        title: 'GLOBAL.BREADCRUMBS.expedientes' // Clave de traducción para el breadcrumb principal
      }
    },
    children: [
      {
        path: '',
        name: 'expedientes:Listado',
        component: ListadoExpedientes,
        meta: {
          auth: true,
          entidad: 'expedientes',
          breadcrumb: {
            title: "GLOBAL.BREADCRUMBS.listado"
          }
        }
      },
      {
        path: 'nuevo',
        name: 'expedientes:Nuevo',
        component: NuevoExpediente,
        meta: {
          auth: true,
          entidad: 'expedientes',
          breadcrumb: {
            title: 'GLOBAL.BREADCRUMBS.nuevo'
          }
        },
      },
      {
        path: ':id',
        name: 'expedientes:Detalle',
        component: DetalleExpediente,
        meta: {
          auth: true,
          entidad: 'expedientes',
          breadcrumb: {
            title: 'GLOBAL.BREADCRUMBS.detalle'
          }
        },
      }
    ]
  }
];

export default expedientesRoutes;

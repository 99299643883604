<template>
<DireccionModal
  :show="modalDireccion"
  v-model:direccion="direccion"
  v-model:relacion="relacion.nombre"
  @crearDireccion="nuevaDireccion"
  @guardarDireccion="modificarDireccion"
  @cancelled="modalDireccion=$event"
  ref="direccionesModal"
/>
  <CCard class="mb-2">
    <CCardHeader>
    <div class="d-flex align-items-center justify-content-between">
      <span class="title-card-list">
        <CIcon name="cil-location-pin" class="me-2" />
        {{ title }}
      </span>

    <div class="mt-1 header-button-container">
      <CButton :disabled="loading" class="send-button" size="sm" color="primary" @click="direccionModal()">
        {{$t('GLOBAL.BTN.nueva')}}
      </CButton>
    </div>
    </div>
  </CCardHeader>
  <spinner-ttt :show="loading"></spinner-ttt>
  <CCardBody>
    <CSmartTable
      v-if="loaded"
      v-model:items="items"
      :columns="camposDirecciones"
      :tableProps="{
            hover: true,
            striped: true,
            responsive: configResponsive
          }"
      columnSorter
      selectable
      clickable-rows
      @row-click="direccionModal"
      :no-items-label="$t('GLOBAL.LISTADOS.sin_resultados')"
      @selected-items-change="items => itemsSeleccionados = items"
    >
    <template #principal="{item}">
      <td>
        <div>
          <CIcon v-if="item.principal" @click="hacerPrincipal(item, false)" name="estrellaRellenada"/>
          <CIcon v-else @click="hacerPrincipal(item, true)" name="estrella" />
        </div>
      </td>
    </template>

    <template #tipo="{item}">
      <td>
        {{ item.tipo }}
      </td>
    </template>

    <template #cif="{item}">
      <td>
        {{ item.cif ?? '' }}
      </td>
    </template>

    <template #nombre="{item}">
      <td>
        {{ item.nombre ?? '' }}
      </td>
    </template>

    <template #opciones="{item}">
      <td class="px-0">
        <div class="dropdown">
          <CIcon class="dropdown-toggle" size="lg" name="cil-options" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li @click="eliminar(item)"><span class="dropdown-item"><CIcon size="lg" name="cil-trash" class="me-2 text-danger"/>{{$t('GLOBAL.BTN.eliminar')}} </span></li>
          </ul>
        </div>

      </td>
    </template>
    </CSmartTable>
    <h3 class="text-center mt-4 mb-5" v-if="items.length < 1">
      {{$t('GLOBAL.LISTADOS.sin_resultados')}}
    </h3>
    <div d-flex flex-row class="acciones-disponibles ms-3">
          <div class="col-12 col-md-auto">
            <CDropdown>
              <CDropdownToggle size="sm" color="primary">{{$t('GLOBAL.LISTADOS.acciones_globales')}}</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click="modalDelete()"><CIcon name="cil-trash" class="me-2 text-danger"/> {{$t('GLOBAL.BTN.eliminar')}}</CDropdownItem>
                <CDropdownItem @click="modalDestacar()"><CIcon name="estrellaRellenada" class="me-2"/> {{$t('GLOBAL.BTN.destacar')}}</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
    </div>
  </CCardBody>
  </CCard>
  <toast ref="toast"></toast>
</template>

<script>
import DireccionModal from '@/app/shared/components/modals/DireccionModal'
import { CONFIG_PANEL } from '@/config';

export default {
  name: 'DireccionesList',
  components: {
    DireccionModal
  },
  props: {
    elemento: { type: String, default: 'Direcciones' },
    title: { type: String, default: 'Direcciones' },
    loading: Boolean,
    loaded: {type: Boolean, default: true},
    direcciones: { type: Array, default() {return[]}, required: true },
    relacion: {type:Object, required: true},

    rows: { type: Number, default: 0 },
    currentPage:  { type: Number, default: 1 },
    perPage:  { type: Number, default: 25 },
    total_paginas:  { type: Number, default: 1 },
  },
  emits: ['delete', 'destacarSeleccionados', 'deleteDireccion', 'nuevaDireccion', 'modificarDireccion', 'destacar'],
  data() {
    return {
      camposDirecciones: [
        // { key: "id", label: "Id", sorter: true ,  _style: 'width:5%' },
        {key: 'principal', label: '', sorter: false},
        {key: 'nombre', label: this.$t('GLOBAL.CAMPOS.razon_social'), sorter: true},
        {key: 'direccion', label: this.$t('GLOBAL.CAMPOS.direccion'), sorter: true},
        {key: 'codigo_postal', label: this.$t('GLOBAL.CAMPOS.cp'), sorter: true},
        {key: 'provincia', label: this.$t('GLOBAL.CAMPOS.provincia'), sorter: false},
        {key: 'opciones', label: '', sorter: false}
      ],
      elemento:  this.$tc('GLOBAL.ELEMENTOS.direccion', 1), //'Dirección',
      direccion: {},
      modalDireccion: false,
      itemsSeleccionados: [],
      deleteItem: {},
    }
  },
  computed: {
    items:{
      get: function () {
        return this.direcciones;
      },
      set: function (newValue) {
        this.$emit("update:direcciones", newValue);
      },
    },
    currentPageSon: {
      get: function() {
        return this.currentPage;
      },
      set: function(newValue) {
        this.$emit('update:currentPage', newValue)
      }
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    }
  },
  methods: {
    getRowCount: function() {
      return this.rows;
    },

    direccionModal(item = null, index, column, e) {
      if(!item || (item && ![ 'alias', 'principal', undefined].includes(column))) {
        if (item) {
          this.direccion = { ...item};
        }
        this.modalDireccion = true;
      }
    },

    checkearSeleccionados() {
      // Reinicia todos los elementos a no seleccionados
      this.items.forEach(item => item._selected = false);

      // Itera sobre los elementos seleccionados y actualiza el estado correspondiente en items
      this.itemsSeleccionados.forEach(selectedItem => {
        const itemToUpdate = this.items.find(item => item.id === selectedItem.id);
        if (itemToUpdate) {
          itemToUpdate._selected = true;
        }
      });

      // Actualiza la referencia de items
      this.items = [...this.items];

      // Devuelve solo los elementos seleccionados
      return this.items.filter(item => item._selected === true);
    },

    nuevaDireccion() {
      this.$emit('nuevaDireccion', this.direccion);
    },

    modificarDireccion() {
      this.$emit('modificarDireccion', this.direccion);
    },

    modalDelete(){
      const checked = this.checkearSeleccionados();

      if(this.items.length == 1) {
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.eliminar_ultima_direc_error",{elemento : this.elemento}),null, 'error', '5000');
        return;
      }


      if(checked.length){
        this.$emit('delete', true)
      } else {
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.no_seleccionado_para_eliminar_error",{elemento : this.elemento}),null, 'error', '5000');
      }
    },

    modalDestacar(){
      const checked = this.checkearSeleccionados();

      if(checked.length){
        this.$emit('destacarSeleccionados', true)
      } else {
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.no_seleccionado_para_destacar_error",{elemento : this.elemento}),null, 'warn', '5000');
      }
    },

    eliminar(item) {
      if(this.items.length == 1) {
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.eliminar_ultima_direc_error",{elemento : this.elemento}),null, 'error', '5000');
        return;
      }

      this.deleteItem = item;
      this.$emit('deleteDireccion', this.deleteItem)
    },

    hacerPrincipal(item, v) {
      item.principal = v;
      this.$emit('destacar', item);
    },

    cerrarModal() {
      this.$refs.direccionesModal.cerrarModal()
    }
  }
}
</script>

<style>

</style>

import { h, resolveComponent } from 'vue'

const DetalleManual = () => import('@/app/views/manual/views/DetalleManual');



const manualRoutes = [
  {
    path: 'manual',
    name: 'Manual',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      breadcrumb: {
        title: 'GLOBAL.BREADCRUMBS.manual'
      }
    },
    children: [
      {
        path: '',
        name: 'manual:Detalle',
        component: DetalleManual,
        meta: {
          auth: true,
          entidad: 'manual',
          breadcrumb: {
            title: "GLOBAL.BREADCRUMBS.detalle"
          }
        },
      }
    ]
  }
];

export default manualRoutes;
